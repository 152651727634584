import { LogoutIcon } from '@heroicons/react/solid'
import React from 'react'
import "./dropdown.css"

function Dropdown({children,show,className}) {
    if (!show) return null
    return (
        <>
            <div className={`dropdown absolute ${className}`}>
                {children}
            </div>
        </>
    )
}

export default Dropdown