import React from 'react'


function Modals({show,children}) {
    if(!show) return null
  return (
    <div className='modals fixed w-full h-full bg-color[var:(--color7)] z-50 left-0 top-0 backdrop-blur-md'>
        <div className="modal-container rounded w-1/2 mx-auto bg-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <div className="modal-row  px-6 py-6">
                {children}
            </div>
        </div>
    </div>
  )
}

export default Modals