import axios from "axios";
import Cookies from "universal-cookie";
import { TOKEN_NAME, config } from "../config";
const cookies = new Cookies();

export const getLeaveApplicationForm = async () => {
    let token = cookies.get(TOKEN_NAME);
    var res = await axios({ method: "GET", url: `${config.apiurl}leaveapplicationlist`, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res.data

};
export const getAttendanceData = async () => {
    let token = cookies.get(TOKEN_NAME);
    var res = await axios({ method: "GET", url: `${config.apiurl}fetchAttendance`, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res

};
export const postLeaveApplicationForm = async (data,file) => {
    var form_data = new FormData();
    for ( var key in data ) {
        form_data.append(key, data[key]);
    }
    if(file){
        for(let i=0;i<file.length;i++){
            form_data.append('medical_certificates[]', file[i]);
        }
    }
    let token = cookies.get(TOKEN_NAME);
    var res = await axios({ method: "POST", url: `${config.apiurl}leaveapplication_save`, data: form_data , headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res
};

export const verifyLeaveApplication = async (id,action) => {
    var form_data = new FormData();
    form_data.append('id', id);
    form_data.append('action', action);
    let token = cookies.get(TOKEN_NAME);
    var res = await axios({ method: "POST", url: `${config.apiurl}leaveapprove`, data:form_data, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res
}