import React, { useState } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import useGet from "../../hooks/read/useGet";
import toast from "react-hot-toast";
import axios from "axios";
import Cookies from "universal-cookie";
import { config, TOKEN_NAME } from "../../config";
import useDownloadFile from "../../hooks/useDownloadFile";
const cookies = new Cookies();
const WFHApproval = () => {
  const [loading, setLoading] = useState(false); // Loader state
  const [id,setId] = useState(null)
  const {
    list: applicationList,
    paramsObject,
    setGetListParams,
  } = useGet({
    url: "wfhApplicationList",
    initialData: {
      limit:50
    },
    onSuccess: () => {
      // scrollToTop()
      // toast.success('Employees Fetched Successfully')
    },
    onError: () => {
      toast.error(
        "There Was An Error While Fetching The Data Please Try Again Later"
      );
    },
  });
  const handleAction = async (wfhId, action) => {
    try {
      setLoading(true); // Start loader
      const formData = new FormData();

      formData.append("wfh_id", wfhId);
      formData.append("action", action);

      const token = cookies.get(TOKEN_NAME);
      const response = await axios({
        method: "POST",
        url: `${config.apiurl}wfhApproval`, // Replace with your actual API endpoint
        data: formData,
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });

      if (response.status === 200) {
        toast.success(`Application ${action} successful`);
        window.location.reload();
        // Optionally refresh the data or update the status
      }
    } catch (error) {
      toast.error("Application failed:", error);
      alert("There was an error processing your request.");
    }
  };

  const {reportDownload} = useDownloadFile({
    url: 'wfh_report',
    onSuccess: () => {
      setId(null)
    },
    onError: () => {
      setId(null)
    }
  });
  const handleDownloadReport = async (wfhId, fromDate, toDate) => {
    setId(wfhId)
    reportDownload.mutate({wfh_id: wfhId, from_date: fromDate, to_date: toDate})
  };

  return (
    <div className="px-5">
      <div className=" mb-4 flex items-center justify-between">
        <h1 className=" text-xl font-semibold">
          {/* Work from home Punch Ins for :{" "}
          <Moment date={new Date()} format="LL" />{" "} */}
          Work From Home Applications
        </h1>
      </div>

      <table class="table-auto w-full">
        <thead>
          <tr class=" bg-[color:var(--color1)] text-center">
            <th class=" text-base font-semibold text-white py-2 lg:py-2 px-3 lg:px-4 border-l border-transparent">
              Sr.No
            </th>
            <th class=" text-base font-semibold text-white py-2 lg:py-2 px-3 lg:px-4">
              Employee Name
            </th>
            <th class=" text-base font-semibold text-white py-2 lg:py-2 px-3 lg:px-4">
              Reason
            </th>
            <th class=" text-base font-semibold text-white py-2 lg:py-2 px-3 lg:px-4">
              From Date
            </th>
            <th class=" text-base font-semibold text-white py-2 lg:py-2 px-3 lg:px-4">
              To Date
            </th>
            <th class=" text-base font-semibold text-white py-2 lg:py-2 px-3 lg:px-4">
              Status
            </th>
            <th class=" text-base font-semibold text-white py-2 lg:py-2 px-3 lg:px-4">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {applicationList?.data?.data?.map((item, index) => (
            <tr key={index} className={`${item.status === "2" ? 'bg-yellow-200' : 'bg-[#F3F6FF]'}`}>
              <td className="text-center text-dark font-medium text-sm lg:text-base lg:py-2 px-2  border-b border-[#E8E8E8]">
                {index + 1}
              </td>
              <td className="text-center text-dark font-medium text-sm lg:text-base lg:py-2 px-2  border-b border-[#E8E8E8]">
                {item.employee_name}
              </td>
              <td className="text-center text-dark font-medium text-sm lg:text-base lg:py-2 px-2  border-b border-[#E8E8E8]">
                {item.reason}
              </td>
              <td className="text-center text-dark font-medium text-sm lg:text-base lg:py-2 px-2  border-b border-[#E8E8E8]">
                {new Date(item.from_date * 1000).toLocaleDateString()}
              </td>
              <td className="text-center text-dark font-medium text-sm lg:text-base lg:py-2 px-2  border-b border-[#E8E8E8]">
                {new Date(item.to_date * 1000).toLocaleDateString()}
              </td>
              <td className="text-center flex justify-center text-dark font-medium text-sm lg:text-base lg:py-2 px-2  border-b border-[#E8E8E8]">
                <>
                  {item.status === "1" ? (
                    <span className=" text-green-700 px-2 text-xs rounded-md flex items-center justify-center mr-2">
                      Approved
                    </span>
                  ) : (
                    <button
                      className="bg-green-500 text-white px-2 py-1 rounded-md mr-2 text-xs"
                      onClick={() => handleAction(item.wfh_id, "APPROVE")}
                    >
                      Approve
                    </button>
                  )}

                  {item.status === "3" ? (
                    <span className=" text-red-700 px-2 text-xs rounded-md flex items-center justify-center mr-2">
                      Rejected
                    </span>
                  ) : (
                    <button
                      className="bg-red-500 text-white px-2 py-1 rounded-md text-xs"
                      onClick={() => handleAction(item.wfh_id, "REJECT")}
                    >
                      Reject
                    </button>
                  )}
                </>
              </td>
              <td className=" border-b ">
                <div className=" text-center">
                  <button
                    type="button"
                    disabled={id === item.wfh_id}
                    onClick={() =>
                      handleDownloadReport(
                        item.wfh_id,
                        new Date(item.from_date * 1000).toLocaleDateString(),
                        new Date(item.to_date * 1000).toLocaleDateString()
                      )
                    }
                    className=" bg-primary text-white rounded-md p-2 text-xs"
                  >
                    {id === item.wfh_id 
                    ? 'Downloading...' 
                    : "Download Report"}
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WFHApproval;
