import { useNavigate } from 'react-router-dom'

function NotAllowed() {
    const navigate = useNavigate();
    const handleTakeMeBack = () => {
        navigate('/', { replace: true });
    }
    return (
        <div className=' h-screen w-full relative bg-gray-200'>
            <div className=' absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[60%]'>
                <div className='flex items-center bg-white space-x-4 rounded-3xl overflow-hidden'>
                    <div className='flex items-center justify-center bg-red-500 p-12'>
                        <div className=' w-40 h-40'>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-full text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                            </svg>
                        </div>
                    </div>
                    <div className='flex-1 h-full'>
                        <div className=" text-center space-y-3">
                            <h1 className=' font-bold text-6xl'>Hold up !</h1>
                            <h4 className=' font-semibold text-lg text-gray-700'>You are not authorized to visit this page</h4>
                            <button className=" bg-red-500 text-white rounded px-8 py-2 font-semibold" onClick={handleTakeMeBack}>Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotAllowed