import React, { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import useUpload from "../../hooks/useUpload";
import { Button } from "../../components/ui";
import { FaCamera } from "react-icons/fa";
import { detectAllFaces, nets } from "face-api.js";

const TakeSnap = ({ setUploadedImage }) => {
  const [containsFace, setContainsFace] = useState(false);
  const [noOfFaces, setNoOfFaces] = useState(0);
  const [detecting, setDetecting] = useState(false);
  const elementRef = useRef(null);
  const [openCamera, setOpenCamera] = useState(false);
  const [image, setImage] = useState(null);

  const initialCall = useRef(true);

  const webcamRef = useRef(null);

  useEffect(() => {
    if (initialCall.current) {
      loadModel();
      initialCall.current = false;
    }
  }, []);

  const loadModel = async () => {
    try {
      await nets.faceLandmark68Net.loadFromUri("/models");
      await nets.ssdMobilenetv1.loadFromUri("/models");
      console.log("Models loaded");
    } catch (err) {
      console.log("Error loading models", err);
    }
  };

  const detectFace = async (file) => {
    const imgTag = document.getElementById("imgTag");
    setDetecting(true);
    
    const detections = await detectAllFaces(imgTag).withFaceLandmarks();
    
    setDetecting(false);

    const faceDetected = detections.length > 0;
    setContainsFace(faceDetected);
    setNoOfFaces(detections.length);

    // If a face is detected, trigger the upload
    if (faceDetected) {
      
      uploadImage(file);
    } else {
      setUploadedImage(null);
      setImage(null);
      alert("No face detected. Please try again.");
    }
  };

  const videoConstraints = {
    width: elementRef?.current?.offsetWidth,
    height: 300,
    facingMode: "user",
  };

  const uploadDoc = useUpload({
    url: '/uploadImage',
    paramName: 'thumbnail[]',
    onSuccess: (res) => {
      setImage(res?.data?.data[0].image_url);
      setUploadedImage(res?.data?.data[0]);
      setOpenCamera(false);
    }
  });

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const base64Data = imageSrc.replace(/^data:image\/\w+;base64,/, '');
    const blob = base64toBlob(base64Data, 'image/jpeg');
    const file = new File([blob], 'photo.jpg', { type: 'image/jpeg' });
    
    // Set the image in the img tag for face detection
    setImage(URL.createObjectURL(file));
    
    // Show the captured image for face detection
    const imgTag = document.getElementById("imgTag");
    imgTag.src = URL.createObjectURL(file);

    // After the image is captured, detect faces
    detectFace(file);
  };

  const base64toBlob = (base64Data, contentType) => {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  const uploadImage = (file) => {
    // if (image) {
      let data = {
        screen: 'webcheckin',
        file
      };
      uploadDoc.mutate(data);
    // }
  };

  return (
    <div className="" id="cam-wrapper" ref={elementRef}>
      <div className="w-full h-40">
        {openCamera ? (
          <div className="w-full h-full bg-gray-200 flex items-center justify-center">
            {/* Render the camera */}
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              className="w-full h-full object-contain"
            />
          </div>
        ) : (
          <>
            {image ? (
              <img src={image || ""} alt="Captured" className="w-full h-full object-contain" />
            ) : (
              <div className="w-full h-full flex items-center justify-center border border-gray-300">
                <FaCamera className="w-14 h-14 text-gray-400" />
              </div>
            )}
          </>
        )}
      </div>

      {/* Canvas for face detection */}
      <img id="imgTag" src={image} alt="Face Detection" className="hidden" />

      <button
        className="w-full py-2 bg-yellow-400 uppercase text-sm font-semibold flex justify-center space-x-4"
        onClick={() => {
          if (openCamera) {
            capture();
          } else {
            setOpenCamera(true);
          }
        }}
      >
        {openCamera ? (
          <>
            <span className="cursor-pointer text-black hover:bg-gray-100 px-2 py-1 rounded">Take Picture</span>
            <span
              className="cursor-pointer text-white bg-red-500 px-4 py-1 rounded-md hover:bg-red-600"
              onClick={(e) => {
                e.stopPropagation();
                setOpenCamera(false);
              }}
            >
              Close
            </span>
          </>
        ) : (
          <span className="cursor-pointer text-black py-1">Open Camera</span>
        )}
      </button>

      {image && (
        <div className="w-[120px] h-[120px] mt-4">
          <img src={image} alt="Preview" className="w-full h-full" />
          <button
            onClick={() => setImage(null)}
            className="bg-red-500 w-full py-1 rounded mt-2"
          >
            Remove
          </button>
        </div>
      )}
    </div>
  );
};

export { TakeSnap };
