import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import useGet from "../../hooks/read/useGet";
import toast from "react-hot-toast";
import { convertUnixToDateTime, convertUnixToTime } from "../../utility";
import WFHModal from "./WFHModal";

const MarkWFHAttendance = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const {
    list: punchList,
  } = useGet({
    url: "fetch_users_wfh_data",
    onSuccess: () => {
    },
    onError: () => {
      toast.error(
        "There Was An Error While Fetching The Data Please Try Again Later"
      );
    },
  });
  useEffect(() => {
    // Set interval to trigger the modal every 40 minutes (40 * 60 * 1000 milliseconds)
    const modalInterval = setInterval(() => {
      setIsModalOpen(true);
    }, 40 * 60 * 1000); // 40 minutes

    // Clear the interval when the component unmounts
    return () => clearInterval(modalInterval);
  }, []);

  return (
    <div className="px-5">
      <div className=" mb-4 flex items-center justify-between">
        <h1 className=" text-xl font-semibold">
          Work from home Punch Ins for :{" "}
          <Moment date={new Date()} format="LL" />{" "}
        </h1>
        <p className="font-semibold underline-offset-2 underline text-primary font-2xl">
          Required Punch Count :- {punchList?.data?.required_puch_count}
        </p>
      </div>
      <div>
        <div class="text-end flex justify-end text-dark font-medium text-sm lg:text-base lg:py-2 my-4 pb-4">
          <div    onClick={openModal}
            class="bg-[color:var(--color1)] cursor-pointer text-white px-4 py-2 rounded-md ml-2"
          >
           Open Modal
          </div>
          <Link
            to="/wfh/mark"
            class="bg-[color:var(--color1)] text-white px-4 py-2 rounded-md ml-2"
          >
            Mark WFH Attendance
          </Link>
        </div>
      </div>
      <WFHModal isOpen={isModalOpen} closeModal={closeModal} />
      <table class="table-auto w-full">
        <thead>
          <tr class=" bg-[color:var(--color1)] text-center">
            <th class=" text-base font-semibold text-white py-2 lg:py-2 px-3 lg:px-4 border-l border-transparent">
              Sr.No
            </th>
            <th class=" text-base font-semibold text-white py-2 lg:py-2 px-3 lg:px-4">
              Punch Date - Time
            </th>
            <th class=" text-base font-semibold text-white py-2 lg:py-2 px-3 lg:px-4">
              Screen Capture
            </th>
            <th class=" text-base font-semibold text-white py-2 lg:py-2 px-3 lg:px-4">
              Photo
            </th>
          </tr>
        </thead>
        <tbody>
          {punchList?.data?.punch_data?.length <= 0 
          ? <tr>
            <td colSpan={5}>
              <p className=" text-center font-semibold py-3">No Punches Added Yet</p>
            </td>
          </tr> 
          : <>
          {punchList?.data?.punch_data?.map((item, index) => (
            <tr key={index}>
              <td className="text-center text-dark font-medium text-sm lg:text-base lg:py-2 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                {index + 1}
              </td>
              <td className="text-center text-dark font-medium text-sm lg:text-base lg:py-2 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                <span className="text-xs bg-gray-200 px-2 py-1 rounded-md">
                  {convertUnixToDateTime(item.punch_time)}
                </span>
              </td>
              <td className="text-center text-dark font-medium text-sm lg:text-base lg:py-2 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                <a href={item?.full_screen_image_url} target="_blank" className=" underline" rel="noopener noreferrer">
                  View
                </a>
              </td>
              <td className="text-center text-dark font-medium text-sm lg:text-base lg:py-2 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                <a href={item?.image_url} target="_blank" className=" underline" rel="noopener noreferrer">
                  View
                </a>
              </td>
            </tr>
          ))}
          </>}
        </tbody>
      </table>
    </div>
  );
};

export default MarkWFHAttendance;
