import { useState } from "react";
import { useForm } from "react-hook-form";
import useGet from "../read/useGet";
import usePut from "../put/usePut";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const useWFH = () => {
  const [snap, setSnap] = useState(null);
  const navigate = useNavigate();
  const [screenShot, setScreenShot] = useState(null);
  const applyWFHForm = useForm({
    defaultValues: {
      reason: "",
      from_date: "",
      to_date: "",
      isFullDay: true,
    }
  });


  // Hit the api to know if this person is marked for WFH
  const { list: getIfEmployeeIsWFH } = useGet({
    url: '',
    initialData: {}
  })
  // API to apply for WFH
  const { handleAuth: applyWFH } = usePut({
    url: 'wfhapplication_save', // Your API endpoint
    onSuccess: () => {
      toast.success("Applied Successfully");
      navigate(-1)
      applyWFHForm.reset(); // Reset form on successful submission
    },
    onError: (err) => {
      const { status, data } = err.response;
      if (status >= 400 && status < 500) {
        toast.error(data?.message);
      } else if (status >= 500) {
        toast.error("Internal server error");
      }
    },
  });

  const handleApplyWFH = (data) => {
    applyWFH.mutate(data); // Call mutate from the mutation object
  };

  // Api to approve and reject the WFH application
  const { handleAuth: WFHApproval } = usePut({
    url: '',
    onSuccess: (res) => {
      toast.success(" ")
    },
    onError: (err) => {
      const { status, data } = err.response
      if (status >= 400 || status <= 499) {
        toast.error(data?.message)
      } else if (status >= 500 || status <= 599) {
        toast.error('Internal server error')
      }
    },
    refreshUrl: ''
  })
  const handleWFHApproval = (data) => {
    WFHApproval.mutate(data)
  }



  // Api to view the WFH application
  const { list: listOfApplications } = useGet({
    url: '',
    initialData: {}
  })


  // Api to view the WFH logs

  // Api to view the WFH attendance

  // Api to mark the WFH attendance

  return {
    snap,
    setSnap,
    screenShot,
    setScreenShot,
    applyWFHForm,
    handleApplyWFH,
    getIfEmployeeIsWFH,
    listOfApplications,
    handleWFHApproval
  };
};

export default useWFH;
