import { ArrowLeftIcon } from '@heroicons/react/solid'
import { Drawer, Modal } from '@mui/material'
import React, { useState } from 'react'
import EmployeeAssignModule from '../../../components/EmployeeAssignModule'
import { ToastContainer, toast } from 'react-toastify';
import useGet from '../../../hooks/read/useGet';
import useReadWithGet from '../../../hooks/read/useReadWithGetMethod';
import usePut from '../../../hooks/usePut';

const ShiftMappingDrawer = ({ open, onClose }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { list: employeeList, paramsObject, setGetListParams } = useGet({
    url: 'employeelist',
    initialData: {
      limit: 50,
      page_number: 0,
      search_query: ''
    }
  })
  const { list: shiftList } = useReadWithGet({
    url: "shift_listing",
    initialData: {
      limit: 50,
      searchQuery: '',
    }
  })
  const shiftMappingFormData = {
    id: '',
    from_date: '',
    to_date: '',
    reason: '',
    applicableFor: []
  }
  const [shiftMappingData, setShiftMappingData] = useState(shiftMappingFormData)
  const handleSetShiftFormData = (e) => {
    let name = e.target.name
    let value = e.target.value
    let prevShiftData = shiftMappingData
    prevShiftData[name] = value
    setShiftMappingData({ ...shiftMappingData })
  }
  const handleGetSelectedValue = (value) => {
    let prevShiftData = shiftMappingData
    prevShiftData.applicableFor = value
    setShiftMappingData({ ...shiftMappingData })
  }
  const { handleAuth: submitShiftMapping } = usePut({
    url: 'shift_mapping',
    onSuccess: () => {
      toast.success("Shift Mapping Done Successfully !", {
        position: 'top-right'
      });
      setTimeout(() => {
        window.location.reload()
      },1200)
    },
    onError: (err) => {
      alert('There Was An Error White Creating Shift Mapping, Please Ty Again Later')
    }
  })
  const handleSubmitShiftMapping = (e) => {
    e.preventDefault()
    let data = {
      ...shiftMappingData,
      user_map_data: shiftMappingData.applicableFor
    }
    delete data.applicableFor;
    submitShiftMapping.mutate(data)
  }

  const handlePageChange = (val) => {
    setGetListParams(prev => {
      return {
        ...prev,
        page_number: val.selected
      }
    })
  }
  const handleSearchList = (e) => {
    setGetListParams(prev => {
      return {
        ...prev,
        search_query: e.target.value,
        page_number: 0
      }
    })
  }
  return (
    <Drawer anchor={'right'} open={open} onClose={onClose}>
      <div className='h-full bg-white p-8' style={{ width: 'calc(100vw - 650px)' }}>
        <form className=' space-y-8' onSubmit={handleSubmitShiftMapping}>
          <div>
            <div className='flex items-center justify-between  pb-5 border-b border-gray-200'>
              <div className='flex items-center space-x-3'>
                <ArrowLeftIcon className=' text-gray-700 w-8 h-8 cursor-pointer' onClick={onClose} />
                <p className='text-gray-700 text-3xl font-semibold'>Shift Mapping</p>
              </div>
              <button className=' bg-color1 px-5 py-2 text-lg text-white rounded-md'>Save Shift</button>
            </div>
            <div className=' space-y-5 mt-5'>
              <div className='grid grid-cols-3 gap-5'>
                <div>
                  <div className=''>
                    <label className='label'>Shift Name: </label>
                    {/* <input type="text" name='shiftName' required={true} value={shiftMappingData.shiftName} onChange={handleSetShiftFormData} className='input' placeholder='Enter Shift name ' /> */}
                    <select name="id" value={shiftMappingData.id} onChange={handleSetShiftFormData} className='input'>
                      <option value="">--Select Shift--</option>
                      {shiftList.data?.data.map((item, idx) => {
                        return <option key={idx} value={item.id}>{item.shiftName}</option>
                      })}
                    </select>
                  </div>
                </div>
                <div>
                  <div>
                    <label className='label'>Date From: </label>
                    <input type="date" name='from_date' className='input' required={true} value={shiftMappingData.from_date} onChange={handleSetShiftFormData} />
                  </div>
                </div>
                <div>
                  <div>
                    <label className='label'>Date To: </label>
                    <input type="date" name='to_date' className='input' required={true} value={shiftMappingData.to_date} onChange={handleSetShiftFormData} />
                  </div>
                </div>
              </div>
              <div className='grid grid-cols-1 gap-5'>
                <div>
                  <div className='flex items-center flex-wrap'>
                    <label className='label mr-2'>Applicable For: </label>
                    <div className='flex items-center flex-wrap gap-2'>
                      {shiftMappingData?.applicableFor.map((item, itemIdx) => {
                        return <label key={itemIdx} className=' bg-amber-100 text-amber-700 px-4 rounded-md py-1 text-xs capitalize'>{item?.employee_name}</label>
                      })}
                    </div>
                  </div>
                  <button onClick={() => setIsModalOpen(true)} type='button' className=' bg-cyan-100 text-cyan-800 px-8 py-1 font-semibold rounded-md mt-4'>Add</button>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-5">
                <div>
                  <div className=''>
                    <label className='label'>Reason: </label>
                    <textarea name="reason" placeholder='Reason...' value={shiftMappingData.reason} onChange={handleSetShiftFormData} className=' min-h-[10rem] input'></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className='w-[60%] bg-white absolute left-1/2 -translate-x-1/2 top-10 rounded-md min-h-[28rem] h-[28rem] overflow-hidden'>
          <EmployeeAssignModule
            options={{ users: employeeList.data?.data }}
            selected={shiftMappingData.applicableFor}
            onClose={() => setIsModalOpen(false)}
            onChange={handleGetSelectedValue}
            nameKey='employee_name'
            onPageChange={handlePageChange}
            onSearch={handleSearchList}
            paginationData={{
              total_data: employeeList?.data?.count,
              currentPage: paramsObject.page_number,
              limit: paramsObject.limit
            }}
            searchValue={paramsObject?.search_query}
          />
        </div>
      </Modal>
      <ToastContainer />
    </Drawer>
  )
}

export default ShiftMappingDrawer