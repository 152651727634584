import React from 'react';
import { Controller } from 'react-hook-form';

const Input = ({
    control,
    name,
    isPhoneNumber = false,
    rules = {},
    placeholder,
    type = 'text',
    onChangeText,
    ...props
}) => {
    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <>
                    <div className={` ${error ? 'border-red-700' : ' border-gray-400'}flex-row space-x-2`}>
                        {isPhoneNumber &&
                            <div className='py-[7.5px]'>
                                <span className=' text-gray-800 font-manrope_medium'>+91</span>
                            </div>}
                        <input
                            type={type}
                            value={value}
                            onChange={onChange}
                            onBlur={(e) => {
                                onBlur();
                                onChangeText?.(e.target.value);
                            }}
                            placeholder={placeholder}
                            className=' input'
                            {...props}
                        />
                    </div>
                    {error && (
                        <small className=' text-red-700 text-xs mt-1'>{error.message || 'Error'}</small>
                    )}
                </>
            )}
        />
    )
}


export default Input