import { useEffect, useRef } from 'react'

// This hook will return the domNode
// Pass callback function 
// Eg : let domNomde = useClickOutside(() => {
//    function you want to run
// })
function useClickOutside(handler) {
    const domNomde = useRef(null)
    useEffect(() => {
        let onClickOutHandler = (event) => {
            if(!domNomde.current){
                return
            } 
            else if (!domNomde?.current.contains(event.target)) {
                handler()
            }
        }
        document.addEventListener('mousedown', onClickOutHandler)
        return () => {
            document.removeEventListener('mousedown', onClickOutHandler)
        }
    })
  return domNomde
}

export default useClickOutside