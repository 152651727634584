import React, { useState } from 'react'
import Salary from '..'
import DatePicker from "react-datepicker";
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import useRead from '../../../hooks/useRead';
import SelectSearch from 'react-select-search';
import useReadWithGet from '../../../hooks/read/useReadWithGetMethod';
import useDownloadFile from '../../../hooks/useDownloadFile';
import Pagination from '../../../components/pagination';
import CompanyPayroll from '../CompanyPayroll';
import spinner from '../../../assets/images/spinner.gif';
import PrimaryLoader from '../../../components/common/PrimaryLoader';

const getDateMonthString = (date) => {
    let tempDate = new Date(date)
    let month = tempDate.getMonth() + 1
    return tempDate.getFullYear() + "-" + month
}
const ProvidentFund = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()
    let initialData = {
        month_and_year: searchParams.get('month_and_year') ? new Date(searchParams.get('month_and_year')) : new Date(),
        prev_month_and_year: new Date(),
        page_number: searchParams.get('page_number') || 0,
        limit: searchParams.get('limit') || 50,
        search_query: searchParams.get('search_query') || '',
        screen_name: 'PF'
    }

    const { list, paramsObject, setGetListParams } = useRead({
        url: 'getMemberPayouts',
        initialData
    })
    const { reportDownload } = useDownloadFile({
        url: 'salary_report',
        filename: 'provident-fund-report.xlsx'
    })
    const handleSearch = (e) => {
        let value = e.target.value
        let data = {
            ...paramsObject,
            search_query: value,
            page_number: 0
        }
        setGetListParams(data)
        setSearchParams(data)
    }
    const handleDate = (value) => {
        let data = {
            ...paramsObject,
            month_and_year: value
        }
        setGetListParams(data)
        setSearchParams(data)
    }
    const handleDownloadReport = () => {
        let data = {
            month_year: getDateMonthString(paramsObject['month_and_year']),
            type: 'epf'
        }
        reportDownload.mutate(data)
    }
    const handleNavigateToEmployeeDetail = (id, username) => {
        navigate(`/employee-salary-details/${id}/${username}`)
    }
    const handlePageClick = (val) => {
        let data = {
            ...paramsObject,
            page_number: val.selected
        }
        setGetListParams(data)
        setSearchParams(data)
    }
    return (
        <Salary>
            <div className=' mt-14 mb-20'>
                <div className=''>
                    <form className='flex items-end justify-between px-5'>
                        <div className='flex items-end space-x-4'>
                            <div>
                                <input type="text" placeholder='Search By Name...' name='search_query' className='input' onChange={handleSearch} />
                            </div>
                            <div className='min-w-[10rem]  basis-[35%]'>
                                <label className=' roboto label'>Select Month/Year</label>
                                <DatePicker required name='month_and_year' autoComplete='false' className='input' dateFormat="MM/yyyy" showMonthYearPicker selected={paramsObject?.month_and_year} onChange={(value) => handleDate(value, 'month_and_year')} />
                            </div>
                        </div>
                        <div className='px-4'>
                            <div className='flex space-x-4 items-end justify-between'>
                                {/* {salaryList?.length > 0 &&
                                    <div className=''>
                                        <button type='button' onClick={handleDownloadReport} className=" bg-[color:var(--color1)] text-white rounded px-4 py-2">Download Report</button>
                                    </div>} */}
                                <div className=''>
                                    <button type='button' onClick={handleDownloadReport} className=" bg-[color:var(--color1)] text-white rounded px-4 py-2">Download Report</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <CompanyPayroll data={list.data?.total_data} type={'pf'} />

                {list.isLoading ?  <div className=' flex items-center justify-center mt-10'> <PrimaryLoader /></div> 
                : 
                list.data?.data?.length <= 0 
                ? <div className='text-center'><p className=' text-gray-700 text-3xl mt-4'>No Employee/Consultant Found</p></div> 
                : 
                <>
                    <div className="max-w-full overflow-x-auto px-4 mt-8">
                        <table className="table-auto w-full">
                            <thead>
                                <tr className=" bg-[color:var(--color1)] text-center">
                                    <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Sr.No</th>
                                    <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Name</th>
                                    <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">User Type</th>
                                    <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Employer EPF</th>
                                    <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Employee EPF</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.data?.data?.map((data, idx) => {
                                    const { employee_name, user_type, id, employer_epf, epf } = data
                                    return <tr key={idx} className='bg-gray-200 hover:bg-gray-100 cursor-pointer' onClick={() => handleNavigateToEmployeeDetail(id, employee_name)}>
                                        <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-l border-[#E8E8E8]">
                                            {(paramsObject.limit * (parseInt(paramsObject.page_number) + 1)) - (paramsObject.limit - (idx + 1))}
                                        </td>
                                        <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-l border-[#E8E8E8]">{employee_name}</td>
                                        <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-[#E8E8E8] capitalize">{user_type}</td>
                                        <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-[#E8E8E8] capitalize">{employer_epf}</td>
                                        <td className="text-center text-dark font-medium text-sm py-3 px-2 border-b border-[#E8E8E8] capitalize">{epf}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className='px-5 mt-5'>
                        <Pagination
                            currentPage={+paramsObject.page_number}
                            lengthofItems={list.data?.total_count}
                            limit={paramsObject.limit}
                            onPageChange={handlePageClick}
                        />
                    </div>
                </>}
            </div>
        </Salary>
    )
}

export default ProvidentFund