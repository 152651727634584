import React from "react";

import { Link } from "react-router-dom";

export default function WFHModal({ isOpen, closeModal }) {
  // If modal is not open, don't render anything
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 shadow-lg max-w-md w-full">
        <h2 className="text-xl font-semibold mb-4">Reminder For Punch</h2>
        <p className="mb-6">Do you want to proceed to the next page?</p>

        <div className="flex items-center  justify-center">
          {/* No button to close the modal */}
          <button
            onClick={closeModal}
            className="bg-gray-500 text-white px-4 py-2 rounded-md mr-2"
          >
            Close
          </button>
          {/* Yes button to redirect */}
          <Link to="/wfh/mark"    onClick={closeModal}>
            <a className="bg-primary text-white px-4 py-2 rounded-md">Punch</a>
          </Link>
        </div>
      </div>
    </div>
  );
}
