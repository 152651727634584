import React from "react";
import { Button } from "../../components/ui";
import { Link } from "react-router-dom";
import { DownloadIcon } from "@heroicons/react/solid";
import useGet from "../../hooks/read/useGet";
import toast from "react-hot-toast";
import MarkWFHAttendance from "./MarkWFHAttendance";

const UserPunches = ({ data }) => {
  const {
    list: punchList,
    paramsObject,
    setGetListParams,
  } = useGet({
    url: "fetch_users_wfh_data",
    // initialData: initialEmployeeState,
    onSuccess: () => {
      // scrollToTop()
      // toast.success('Employees Fetched Successfully')
    },
    onError: () => {
      toast.error(
        "There Was An Error While Fetching The Data Please Try Again Later"
      );
    },
  });
  return (
    <>
      {punchList?.data?.is_wfh ? (
        <MarkWFHAttendance />
      ) : (
        <>
         {/* <MarkWFHAttendance /> */}
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-x-4">
            <Button>
              <Link to={`/attendance-punch-in`}>Punch Attendance</Link>
            </Button>
            <Button>
              <Link to={`/attendance-punch-in/logs`}>View Punch-in Logs</Link>
            </Button>
          </div>
        </>
      )}
      <div>
        <div className="my-4 space-x-4">
          {data?.appointment_letter && (
            <a
              className="text-lg font-semibold p-5 bg-orange-700 text-white text-center inline-block rounded"
              href={data?.appointment_letter}
              download={true}
            >
              <DownloadIcon className="w-6 h-6 inline-block" />{" "}
              <span className="inline-block">Appointment Letter</span>
            </a>
          )}
          {data?.confirmation_letter && (
            <a
              className="text-lg font-semibold p-5 bg-orange-700 text-white text-center inline-block rounded"
              href={data?.confirmation_letter}
              download={true}
            >
              <DownloadIcon className="w-6 h-6 inline-block" />{" "}
              <span className="inline-block">Confirmation Letter</span>
            </a>
          )}
        </div>
      </div>
    </>
  );
};

export default UserPunches;
