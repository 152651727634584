import { MdOutlineFitScreen } from "react-icons/md";
import html2canvas from "html2canvas";
import React, { useRef, useState, useEffect } from "react";
import useUpload from "../../hooks/useUpload";

const ScreenCapture = ({ setScreenCapture }) => {
  const videoRef = useRef(null); // To hold the video stream
  const [isUploaded, setIsUploaded] = useState(false);
  const [countdown, setCountdown] = useState(null); // For the countdown timer
  const [capturedImage, setCapturedImage] = useState(null); // For preview of the captured image

  const uploadDoc = useUpload({
    url: "/uploadImage",
    paramName: "thumbnail[]",
    onSuccess: (res) => {
      setScreenCapture(res?.data?.data[0]);
      setIsUploaded(true);
    },
  });

  const captureScreen = async () => {
    try {
      // Requesting access to the screen or window
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: { mediaSource: "screen" },
      });

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();

        // Start the countdown
        let counter = 5;
        setCountdown(counter);

        // Countdown logic
        const countdownInterval = setInterval(() => {
          counter -= 1;
          setCountdown(counter);
          if (counter === 0) {
            clearInterval(countdownInterval);

            // Capture the screenshot after countdown ends
            const canvas = document.createElement("canvas");
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;

            const ctx = canvas.getContext("2d");
            ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

            // Convert the canvas to an image and set it for preview
            const imgData = canvas.toDataURL("image/png");
            setCapturedImage(imgData);

            // Stop the video stream
            stream.getTracks().forEach((track) => track.stop());

            // Reset countdown
            setCountdown(null);
          }
        }, 1000); // Update every second
      }
    } catch (err) {
      console.error("Error: ", err);
    }
  };

  const uploadImage = () => {
    if (capturedImage) {
      const blob = dataURLtoBlob(capturedImage);
      const file = new File([blob], "screenshot.png", { type: "image/png" });
      let data = {
        screen: "webcheckin",
        file,
      };
      uploadDoc.mutate(data);
    }
  };

  // Helper function to convert base64 to Blob
  const dataURLtoBlob = (dataURL) => {
    const byteString = atob(dataURL.split(",")[1]); // Decode base64
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0]; // Extract MIME type

    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([intArray], { type: mimeString });
  };

  const captureAgain = () => {
    setCapturedImage(null);
    setIsUploaded(false);
    captureScreen();
  };
  return (
    <div>
      <div className="h-40">
        {capturedImage ? (
          <img
            src={capturedImage}
            alt="Screenshot Preview"
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full flex items-center justify-center border border-gray-300">
            <MdOutlineFitScreen className=" w-14 h-14 text-gray-400" />
          </div>
        )}
        <video ref={videoRef} style={{ display: "none" }}></video>
        <div>
          {capturedImage ? (
            <div className=" text-center bg-yellow-400 space-x-4 py-2">
              {isUploaded ? (
                null
              ) : (
                <button
                disabled={uploadDoc.isLoading}
                  onClick={uploadImage}
                  className="py-2 px-5 bg-blue-400 uppercase text-xs font-semibold rounded"
                >
                  {uploadDoc.isLoading ? 'Uploading..' : 'Upload'} 
                </button>
              )}
              <button
                onClick={captureAgain}
                className="py-2 px-5 bg-red-400 uppercase text-xs font-semibold rounded"
              >
                Capture Again
              </button>
            </div>
          ) : (
            <button
              onClick={captureScreen}
              className="w-full py-3 bg-yellow-400 uppercase text-sm font-semibold"
            >
              Capture Screen
            </button>
          )}
        </div>
      </div>

      <div className=" mt-12">
        {countdown !== null && <h2>Capturing in: {countdown}</h2>}
      </div>
    </div>
  );
};

export default ScreenCapture;
